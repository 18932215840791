import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import api from "../../components/helpers/api";
import PageHeader from "../../components/layouts/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import clientRoutes from "../../routes/clientRoutes";

const Categories = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [categories, setCategories] = useState([]);

  document.title = t("Categories");
  useEffect(() => {
    api
      .get(`${apiRoutes.categories.all}`)
      .then((response) => {
        const { data } = response.data;
        setCategories(data);
      })
      .catch(() => {});
  }, []);

  const goToCategory = (category) => {
    history.push(
      `${clientRoutes.categories.show}/${category.id}/${category.slug}`
    );
  };

  return (
    <div>
      <PageHeader title={t("Categories")} />
      <Container className="mt-3">
        <Row>
          {categories.map((category, index) => (
            <Col md={3} key={index} className="mt-3">
              <Card className="bg-dark text-white">
                <Card.Img
                  src={category.image?.card_url ?? "/back-small.jpg"}
                  alt={category.title}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: 200,
                  }}
                />
                <Card.ImgOverlay
                  className="main-headlines"
                  onClick={() => goToCategory(category)}
                >
                  <h2>{category.title}</h2>
                </Card.ImgOverlay>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Categories;
