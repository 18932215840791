import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FormCheck from "../../components/inputs/FormCheck";
import FormInput from "../../components/inputs/FormInput";
import { storeLoginToken } from "../../components/helpers";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import api from "../../components/helpers/api";
import { withTranslation } from "react-i18next";
import Formcard from "../../components/inputs/FormCard";
import clientRoutes from "../../routes/clientRoutes";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      remember: false,
      redirect: new URLSearchParams(this.props.location.search).get("redirect"),
      errors: {},
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Login");
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      email: this.state.email,
      password: this.state.password,
      remember: this.state.remember,
    };
    return api
      .post(`${apiRoutes.auth.login}`, data)
      .then((res) => {
        storeLoginToken(res.data.access_token, this.state.remember);
        // dispatch action
        this.props.setLogin(res.data.user);
        if (!res.data.user.email_verified_at) {
          window.location = clientRoutes.auth.verifyEmail;
        } else if (this.state.redirect) {
          window.location = this.state.redirect;
        } else {
          window.location = clientRoutes.home.index;
        }
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { email: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={4} className="mx-auto mt-5 mb-4">
            <Formcard>
              <h3>{t("Login")}</h3>
              <hr />
              <Form>
                <FormInput
                  label={t("Email address")}
                  placeholder={t("Email address")}
                  type="email"
                  name="email"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.email}
                  errorMessage={
                    this.state.errors.email && this.state.errors.email[0]
                  }
                />
                <FormInput
                  label={t("Password")}
                  placeholder={t("Password")}
                  type="password"
                  name="password"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.password}
                  errorMessage={
                    this.state.errors.password && this.state.errors.password[0]
                  }
                />

                <FormCheck
                  label={t("Remember me")}
                  name="remember"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.remember}
                  errorMessage={
                    this.state.errors.remember && this.state.errors.remember[0]
                  }
                />

                <Form.Group className="mb-3">
                  <Link to={clientRoutes.auth.forgetPassword}>
                    {t("Forgot password")}
                  </Link>
                </Form.Group>
                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="d-block w-100"
                  onClick={this.handleForm}
                >
                  {t("Login")}
                </LoadingButton>
                <div className="mt-5">{t("If you don't have an account")}</div>
                <Link
                  to={clientRoutes.auth.register}
                  className="btn d-block btn-outline-primary mt-3"
                >
                  {t("Register")}
                </Link>
              </Form>
            </Formcard>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
      }),
  };
};
export default withTranslation()(connect(null, mapDispatchToProps)(Login));
