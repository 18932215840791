import { createStore } from "redux";
import AllReducers from "./reducers";

const initialStates = {
  auth: {
    loggedIn: false,
    user: {},
  },
  socialMedia: {
    data: [],
  },
  categories: {
    nav: [],
  },
};

const store = createStore(
  AllReducers,
  initialStates,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
