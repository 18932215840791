import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FormCard from "../../components/inputs/FormCard";
import ChangePassword from "../../components/profile/ChangePassword";
import Profilenavs from "../../components/profile/ProfileNavs";
import UserSettings from "../../components/profile/UserSettings";

const Profile = () => {
  const { t } = useTranslation();
  document.title = t("Profile");

  return (
    <Container className="mt-5">
      <h2>{t("Profile")}</h2>
      <Profilenavs />
      <FormCard>
        <FormCard>
          <h3>{t("User Settings")}</h3>
          <hr />
          <UserSettings />
        </FormCard>
        <hr />
        <FormCard>
          <h3>{t("Change Password")}</h3>
          <hr />
          <ChangePassword />
        </FormCard>
      </FormCard>
    </Container>
  );
};

export default Profile;
