import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "../layouts/Layout";
import { Spinner } from "react-bootstrap";
import { useQuery } from "../components/utils";

const GuestRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const query = useQuery();
  const redirect = query.get("redirect");

  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !rest.loggedIn ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: redirect ?? "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};
export default connect(mapStateToProps)(GuestRoute);
