import React from "react";
import { Card, Container } from "react-bootstrap";

const PageHeader = (props) => {
  const { title, subtitle, cover } = props;
  return (
    <div>
      <Card className="bg-dark text-white" style={{ borderRadius: 0 }}>
        <Card.Img
          style={{
            objectFit: "cover",
            width: "100%",
            height: 500,
          }}
          src={cover ?? "/back.resized.jpg"}
          alt="Card image"
        />
        <Card.ImgOverlay
          style={{
            backgroundColor: "rgba(10,10,10,50%)",
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Container>
            <h1>{title}</h1>
            {subtitle && (
              <Card.Text style={{ fontSize: 20, whiteSpace: "pre-wrap" }}>
                {subtitle}
              </Card.Text>
            )}
            {props.children}
          </Container>
        </Card.ImgOverlay>
      </Card>
    </div>
  );
};

export default PageHeader;
