import { getLang } from "../lang";

const lang = getLang() ?? "en";

const query = new URLSearchParams(window.location.search);

const redirect = query.get("redirect");

const clientRoutes = {
  home: {
    index: `/${lang}`,
  },
  auth: {
    register: `/${lang}/register${redirect ? `?redirect=${redirect}` : ""}`,
    login: `/${lang}/login${redirect ? `?redirect=${redirect}` : ""}`,
    forgetPassword: `/${lang}/forgot/password${
      redirect ? `?redirect=${redirect}` : ""
    }`,
    checkPassword: `/${lang}/check/password${
      redirect ? `?redirect=${redirect}` : ""
    }`,
    resetPassword: `/${lang}/reset/password${
      redirect ? `?redirect=${redirect}` : ""
    }`,
    verifyEmail: `/${lang}/verify/email${
      redirect ? `?redirect=${redirect}` : ""
    }`,
  },
  profile: {
    index: `/${lang}/profile`,
    mycourses: `/${lang}/mycourses`,
  },
  categories: {
    index: `/${lang}/categories`,
    show: `/${lang}/category`,
  },
  courses: {
    show: `/${lang}/courses`,
    pay: `/${lang}/courses/pay`,
  },
  lessons: {
    show: `/${lang}/lessons`,
    files: `/${lang}/lessons`,
    videos: `/${lang}/lessons`,
    audios: `/${lang}/lessons`,
  },
  articles: {
    index: `/${lang}/articles`,
    show: `/${lang}/articles`,
  },
  sitePolicies: {
    aboutus: `/${lang}/aboutus`,
    privacy: `/${lang}/privacy`,
    faq: `/${lang}/faq`,
    terms: `/${lang}/terms_and_conditions`,
  },
};

export default clientRoutes;
