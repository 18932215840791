import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath, useLocation } from "react-router";
import { Spinner } from "react-bootstrap";
import Layout from "../layouts/Layout";
import { getLang } from "../lang";

const lang = getLang();

const renderComponent = (props, user, loggedIn, Component, location) => {
  if (loggedIn) {
    if (user?.email_verified_at) {
      if (
        !!matchPath(location.pathname, {
          path: `/${lang}/verify/email`,
          exact: true,
          strict: false,
        })
      ) {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        );
      } else {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
    } else {
      if (
        !!matchPath(location.pathname, {
          path: `/${lang}/verify/email`,
          exact: true,
          strict: false,
        })
      ) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: `/${lang}/verify/email`,
              state: { from: props.location },
            }}
          />
        );
      }
    }
  } else {
    window.location.href = `/${lang}/login?redirect=${location.pathname}`;
    return (
      <Redirect
        to={{
          pathname: `${lang}/login?redirect=${location.pathname}`,
          state: { from: props.location },
        }}
      />
    );
  }
};

const AuthRoute = ({ component: Component, ...rest }) => {
  let location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        renderComponent(props, rest.user, rest.loggedIn, Component, location)
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(AuthRoute);
