import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CourseCard from "../../components/courses/CourseCard";
import api from "../../components/helpers/api";
import FormCard from "../../components/inputs/FormCard";
import Profilenavs from "../../components/profile/ProfileNavs";
import apiRoutes from "../../routes/apiRoutes";
import clientRoutes from "../../routes/clientRoutes";

const MyCourses = () => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  document.title = t("My Courses");

  useEffect(() => {
    api
      .get(`${apiRoutes.profile.courses}`)
      .then((response) => setCourses(response.data))
      .catch(() => {});
  }, []);

  return (
    <Container className="mt-5">
      <h2>{t("My Courses")}</h2>
      <Profilenavs />
      <FormCard>
        <h3>{t("My Courses")}</h3>
        <hr />
        <Row>
          {courses?.map((course, index) => (
            <Col md={3} className="mt-3" key={index}>
              <CourseCard
                title={course.title}
                description={course.description}
                price={course.price}
                image={course.image?.card_url}
                url={`${clientRoutes.courses.show}/${course.id}/${course.slug}`}
              />
            </Col>
          ))}
          {courses.length === 0 && (
            <div className="text-center text-muted h4">
              {t("No courses yet")}
            </div>
          )}
        </Row>
      </FormCard>
    </Container>
  );
};

export default MyCourses;
