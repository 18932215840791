import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Button, ButtonGroup } from "react-bootstrap";
import api from "../../components/helpers/api";
import LessonContainer from "../../components/lessons/LessonContainer";
import apiRoutes from "../../routes/apiRoutes";
import { Document, Page, pdfjs } from "react-pdf";
import { getLang } from "../../lang";
import cookie from "js-cookie";
import { FileTypeTitle } from "../../components/lessons/LessonCollapse";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WrapperDiv = (props) => {
  const wrapperDiv = useRef(null);
  const [divWidth, setDivWidth] = useState(0);
  useEffect(() => {
    setDivWidth(wrapperDiv.current.offsetWidth);
  }, [wrapperDiv]);
  return (
    <div
      ref={wrapperDiv}
      style={{
        padding: 10,
        width: "100%",
      }}
    >
      <Page
        scale={props.scale}
        pageNumber={props.index}
        width={divWidth - 20}
      />
    </div>
  );
};

const renderPages = (numPages, scale = 1) => {
  const pages = [];
  for (let index = 1; index <= numPages; index++) {
    pages.push(<WrapperDiv key={index} index={index} scale={scale} />);
  }
  return pages;
};

const LessonFile = (props) => {
  const lessonId = props.match?.params?.id;
  const lessonSlug = props.match?.params?.slug;
  const fileId = props.match?.params?.fileId;
  const history = useHistory();

  const [file, setFile] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [lesson, setLesson] = useState({});
  const [scale, setScale] = useState(1);

  const fileUrl = useMemo(
    () => ({
      url:
        `${process.env.REACT_APP_API_URL}/api/${getLang()}/` +
        apiRoutes.lessonFiles.showPDF +
        "/" +
        fileId,
      httpHeaders: { Authorization: "Bearer " + cookie.get("token") },
    }),
    [fileId]
  );

  useEffect(() => {
    api
      .get(`${apiRoutes.lessonFiles.show}/${lessonId}/${fileId}`)
      .then((response) => setFile(response.data.data))
      .catch(() => {});
  }, [lessonId, lessonSlug, fileId, history]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = async () => {
    return api
      .get(apiRoutes.lessonFiles.showPDF + "/" + fileId, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${file.title}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {});
  };

  return (
    <div>
      <LessonContainer
        lesson={lesson}
        lessonSlug={lessonSlug}
        setLesson={setLesson}
        lessonId={lessonId}
        fileId={fileId}
        fileType="file"
      >
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mt-3">
            <FileTypeTitle type="file" /> {file.title}
          </h4>
          {lesson.course?.downloadable && (
            <div>
              <Button onClick={handleDownload} variant="outline-secondary">
                <i className="fa fa-cloud-download-alt"></i> Download
              </Button>
            </div>
          )}
        </div>
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className="position-relative"
        >
          <ButtonGroup
            aria-label="Zoom"
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              zIndex: 1000,
            }}
          >
            <Button
              className="rounded-0"
              variant="outline-primary"
              onClick={() => setScale(scale / 2)}
            >
              <i className="fa fa-search-minus"></i>
            </Button>
            <Button
              className="rounded-0"
              variant="outline-primary"
              onClick={() => setScale(scale * 2)}
            >
              <i className="fa fa-search-plus"></i>
            </Button>
          </ButtonGroup>
          <div
            style={{
              height: "100vh",
              overflowY: "scroll",
              backgroundColor: "grey",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {renderPages(numPages, scale)}
          </div>
        </Document>
      </LessonContainer>
    </div>
  );
};

export default LessonFile;
