import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import { FileTypeTitle } from "../../components/lessons/LessonCollapse";
import LessonContainer from "../../components/lessons/LessonContainer";
import clientRoutes from "../../routes/clientRoutes";

const Lesson = (props) => {
  const lessonId = props.match?.params?.id;
  const lessonSlug = props.match?.params?.slug;

  const [lesson, setLesson] = useState({});

  return (
    <div>
      <LessonContainer
        lessonSlug={lessonSlug}
        setLesson={setLesson}
        lesson={lesson}
        lessonId={lessonId}
      >
        <ListGroup>
          {lesson.files?.map((file, index) => (
            <ListGroup.Item
              key={index}
              action
              className="p-3 h5 m-0"
              href={`${clientRoutes.lessons.files}/${lesson.id}/${lesson.slug}/files/${file.id}`}
            >
              <FileTypeTitle type="file" /> {file.title}
            </ListGroup.Item>
          ))}
          {lesson.videos?.map((video, index) => (
            <ListGroup.Item
              key={index}
              action
              className="p-3 h5 m-0"
              href={`${clientRoutes.lessons.videos}/${lesson.id}/${lesson.slug}/videos/${video.id}`}
            >
              <FileTypeTitle type="video" /> {video.title}
            </ListGroup.Item>
          ))}
          {lesson.audios?.map((audio, index) => (
            <ListGroup.Item
              key={index}
              action
              className="p-3 h5 m-0"
              href={`${clientRoutes.lessons.audios}/${lesson.id}/${lesson.slug}/audios/${audio.id}`}
            >
              <FileTypeTitle type="audio" /> {audio.title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </LessonContainer>
    </div>
  );
};

export default Lesson;
