import { withSnackbar } from "notistack";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import apiRoutes from "../../routes/apiRoutes";
import api from "../helpers/api";
import FormInput from "../inputs/FormInput";
import LoadingButton from "../LoadingButton";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      old_password: "",
      password: "",
      password_confirmation: "",
      errors: {},
    };
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      old_password: this.state.old_password,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };
    this.setState({
      errors: {},
    });
    return api
      .patch(`${apiRoutes.profile.updatePassword}`, data)
      .then(() => {
        // dispatch action
        this.setState({
          old_password: "",
          password: "",
          password_confirmation: "",
        });
        this.props.enqueueSnackbar(t("Updated successfully"), {
          variant: "success",
        });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { old_password: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Form>
        <FormInput
          label={t("Old Password")}
          placeholder={t("Old Password")}
          type="password"
          name="old_password"
          labelClassName="required"
          value={this.state.old_password}
          onChange={this.handleInput}
          isInvalid={this.state.errors.old_password}
          errorMessage={
            this.state.errors.old_password && this.state.errors.old_password[0]
          }
        />

        <FormInput
          label={t("New Password")}
          placeholder={t("New Password")}
          type="password"
          name="password"
          labelClassName="required"
          value={this.state.password}
          onChange={this.handleInput}
          isInvalid={this.state.errors.password}
          errorMessage={
            this.state.errors.password && this.state.errors.password[0]
          }
        />

        <FormInput
          label={t("Password Confirmation")}
          placeholder={t("Password Confirmation")}
          type="password"
          name="password_confirmation"
          labelClassName="required"
          value={this.state.password_confirmation}
          onChange={this.handleInput}
          isInvalid={this.state.errors.password_confirmation}
          errorMessage={
            this.state.errors.password_confirmation &&
            this.state.errors.password_confirmation[0]
          }
        />
        <LoadingButton
          variant="primary"
          type="submit"
          className="d-block w-100"
          onClick={this.handleForm}
        >
          {t("Update Password")}
        </LoadingButton>
      </Form>
    );
  }
}

export default withTranslation()(withSnackbar(ChangePassword));
