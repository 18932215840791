import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import api from "../components/helpers/api";
import FormInput from "../components/inputs/FormInput";
import LoadingButton from "../components/LoadingButton";
import apiRoutes from "../routes/apiRoutes";

const ContactForm = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState(props.user?.name);
  const [email, setEmail] = useState(props.user?.email);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    return api
      .post(`${apiRoutes.contactus.send}`, {
        name,
        email,
        message,
      })
      .then(() => {
        enqueueSnackbar(t("Sent successfully"), {
          variant: "success",
        });
        setMessage("");
        props.onHide();
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          setErrors(response.data.errors);
        } else {
          setErrors({ name: [t("Something went wrong")] });
        }
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Leave us a message")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <FormInput
                label={t("Name")}
                placeholder={t("Name")}
                type="text"
                name="name"
                labelClassName="required"
                value={name}
                onChange={(event) => setName(event.target.value)}
                isInvalid={errors.name}
                errorMessage={errors.name && errors.name[0]}
              />
            </Col>
            <Col>
              <FormInput
                label={t("Email address")}
                placeholder={t("Email address")}
                type="email"
                name="email"
                labelClassName="required"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                isInvalid={errors.email}
                helpText={`${t(
                  "We'll never share your email with anyone else"
                )}.`}
                errorMessage={errors.email && errors.email[0]}
              />
            </Col>
          </Row>

          <FormInput
            label={t("Message")}
            as="textarea"
            placeholder={t("Leave a message here")}
            style={{ height: "100px" }}
            type="text"
            name="email"
            labelClassName="required"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            isInvalid={errors.message}
            errorMessage={errors.message && errors.message[0]}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <LoadingButton onClick={handleSubmit}>
          {t("Send")} <i className="fa fa-send"></i>
        </LoadingButton>
        <Button variant="danger" onClick={props.onHide}>
          {t("Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(ContactForm);
