import React from "react";
import LessonCollapse from "./LessonCollapse";

const LessonSideBar = (props) => {
  const { lessons, lessonId, fileId, fileType, closeSideBar } = props;
  return (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <ul className="list-group ps-0" style={{ listStyle: "none" }}>
        {lessons?.map((lesson, index) => (
          <LessonCollapse
            lesson={lesson}
            lessonId={lessonId}
            fileId={fileId}
            fileType={fileType}
            key={index}
            closeSideBar={closeSideBar}
          />
        ))}
      </ul>
    </div>
  );
};

export default LessonSideBar;
