import React, { useEffect, useState } from "react";
import { Col, Container, Pagination, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ArticleCard from "../../components/blog/ArticleCard";
import api from "../../components/helpers/api";
import PageHeader from "../../components/layouts/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import clientRoutes from "../../routes/clientRoutes";

const Articles = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  document.title = t("Blog");

  useEffect(() => {
    api
      .get(`${apiRoutes.blog.all}?page=${page}`)
      .then((response) => {
        const { data, meta } = response.data;
        setArticles(data);
        setPage(meta.current_page);
        setPages(meta.last_page);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          history.push("/404");
        }
      });
  }, [history, page]);

  return (
    <div>
      <PageHeader title={t("Blog")} />
      <Container className="mt-5">
        <Row>
          {articles?.map((article) => (
            <Col md={3} className="mt-3" key={article.id}>
              <ArticleCard
                title={article.title}
                comments={article.comments_count}
                headline={article.headline}
                createdAt={article.created_at}
                image={article.image?.card_url ?? ""}
                authorImage={article.author_image?.thumb_url ?? ""}
                authorName={article.author_name}
                url={`${clientRoutes.articles.show}/${article.id}/${article.slug}`}
              />
            </Col>
          ))}
        </Row>
        {!articles.length ? (
          <h2 className="text-muted text-center mt-5 mb-5">
            {t("No published articles yet")}!!
          </h2>
        ) : (
          <div className="mt-5 justify-content-center d-flex">
            <Pagination>
              <Pagination.Prev
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              />
              <Pagination.Next
                disabled={page === pages}
                onClick={() => setPage(page + 1)}
              />
            </Pagination>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Articles;
