import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import apiRoutes from "../../routes/apiRoutes";
import api from "../helpers/api";
import LoadingButton from "../LoadingButton";

const LessonComments = (props) => {
  const { lessonId } = props;
  const [comments, setComments] = useState([]);
  const [contents, setContents] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [totalComments, setTotalComments] = useState(0);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    api
      .get(`${apiRoutes.lessonComments.show}/${lessonId}?page=${page}`)
      .then((response) => {
        const { data, meta } = response.data;
        setComments(data);
        setPage(meta.current_page);
        setPages(meta.last_page);
        setTotalComments(meta.total);
      })
      .catch();
  }, [lessonId, page]);

  const handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    setContents(value);
  };

  const sendComment = () => {
    return api
      .post(`${apiRoutes.lessonComments.store}/${lessonId}`, { contents })
      .then((res) => {
        setContents("");
        enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("Something went wrong"), {
          variant: "error",
        });
      });
  };
  return (
    <div className="mt-5">
      <div className="headings d-flex justify-content-between align-items-center mb-3">
        <h5>
          {t("Comments")} ({totalComments})
        </h5>
      </div>
      {comments.map((comment, index) => (
        <div className="card" key={index}>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="user d-flex flex-row align-items-center">
                {/* <img
              src="https://i.imgur.com/hczKIze.jpg"
              alt="https://i.imgur.com/hczKIze.jpg"
              width="30"
              className="user-img rounded-circle mx-2"
            />{" "} */}
                <small className="font-weight-bold text-primary">
                  {comment.user?.name}
                </small>
                &nbsp;&nbsp;
                <small
                  className="font-weight-bold"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {comment.contents}
                </small>
              </div>
              <small style={{ minWidth: 70 }}>
                {moment(comment.created_at).fromNow()}
              </small>
            </div>
          </div>
        </div>
      ))}

      <div>
        <Pagination>
          <Pagination.Prev
            disabled={page === 1}
            onClick={() => setPage(page - 1)}
          />
          <Pagination.Next
            disabled={page === pages}
            onClick={() => setPage(page + 1)}
          />
        </Pagination>
      </div>

      {props.loggedIn && (
        <div className="card">
          <div className="card-body">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* <div>
              <img
                src="https://i.imgur.com/xELPaag.jpg"
                alt="https://i.imgur.com/xELPaag.jpg"
                width="70"
                className="rounded-circle mt-2"
              />
            </div> */}
              <div style={{ flex: 1 }} className="mx-3">
                <div className="comment-box ml-2">
                  <h4>{t("Add a comment")}</h4>
                  <div className="comment-area">
                    <textarea
                      className="form-control"
                      value={contents}
                      onChange={handleInput}
                      name="contents"
                      placeholder={`${t("Add a comment")}`}
                      rows="4"
                    />
                  </div>
                  <div className="comment-btns mt-2">
                    <LoadingButton
                      onClick={sendComment}
                      className="btn btn-primary"
                    >
                      {t("Send")}{" "}
                      <i className="fas fa-long-arrow-right ml-1"></i>
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(LessonComments);
