import React from "react";
import { Carousel, Container, Image } from "react-bootstrap";
import testimonials from "../../config/testimonials";
import { useTranslation } from "react-i18next";

const containerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const TestimonialsSection = () => {
  const { t } = useTranslation();

  return (
    <Container className="mt-5">
      <Carousel
        variant="dark"
        style={{ minHeight: 200, height: "100%" }}
        controls={true}
        indicators={false}
      >
        {testimonials.map((testimonial, index) => (
          <Carousel.Item key={index}>
            <div style={containerStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "80%",
                  margin: "auto",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div className="p-3">
                  <Image
                    src={testimonial.image}
                    roundedCircle
                    style={{ height: 100, objectFit: "cover" }}
                  />
                </div>
                <div className="p-3">
                  <h3>{t(testimonial.name)}</h3>
                  <div className="text-muted">
                    <span>
                      <i className="fa fa-briefcase text-dark"></i>{" "}
                      {t(testimonial.job)}
                    </span>
                    &nbsp; - &nbsp;
                    <span>
                      <i className="fa fa-map-marker-alt text-dark"></i>{" "}
                      {t(testimonial.country)}
                    </span>
                  </div>
                  <p style={{ textAlign: "justify" }}>
                    {t(testimonial.comment)}
                  </p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Container>
  );
};

export default TestimonialsSection;
