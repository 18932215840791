import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import clientRoutes from "../../routes/clientRoutes";

const Profilenavs = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Nav variant="pills" defaultActiveKey={history.location.pathname}>
      <Nav.Item>
        <Nav.Link href={clientRoutes.profile.index}>{t("Profile")}</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href={clientRoutes.profile.mycourses}>
          {t("Courses")}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default Profilenavs;
