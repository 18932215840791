import React, { useEffect } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import apiRoutes from "../../routes/apiRoutes";
import clientRoutes from "../../routes/clientRoutes";
import api from "../helpers/api";
import LessonComments from "./LessonComments";
import LessonOffcanvas from "./LessonOffcanvas";
import LessonSideBar from "./LessonSideBar";

const LessonContainer = (props) => {
  const { lesson, lessonId, fileId, fileType, lessonSlug, setLesson } = props;
  const history = useHistory();

  useEffect(() => {
    api
      .get(`${apiRoutes.lessons.show}/${lessonId}/${lessonSlug}`)
      .then((response) => {
        const { data } = response.data;
        document.title = data.title;
        setLesson(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          history.push("/404");
        }
        if (error.response?.status === 403) {
          const course = error.response.data.data;
          history.push(
            `${clientRoutes.courses.pay}/${course.id}/${course.slug}`
          );
        }
      });
  }, [lessonId, lessonSlug, history, setLesson]);
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={3} className="d-none d-lg-block p-0">
            <LessonSideBar
              lessons={lesson.course?.lessons}
              lessonId={lessonId}
              fileId={fileId}
              fileType={fileType}
            />
          </Col>
          <Col lg={9} className="mt-3">
            <Container className="mt-3">
              <div className="d-flex flex-md-row flex-column justify-content-between">
                <h3 className="order-md-first order-last">
                  <i className="fas fa-chalkboard"></i>&nbsp;&nbsp;
                  {lesson.title}
                </h3>
                <Breadcrumb>
                  <Breadcrumb.Item
                    href={`${clientRoutes.courses.show}/${lesson.course?.id}/${lesson.course?.slug}`}
                  >
                    {lesson.course?.title}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{lesson.title}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {props.children}

              <LessonComments lessonId={lessonId} />
            </Container>
          </Col>
        </Row>
        <LessonOffcanvas
          lessons={lesson.course?.lessons}
          lessonId={lessonId}
          fileId={fileId}
          fileType={fileType}
        />
      </Container>
    </div>
  );
};

export default LessonContainer;
