import axios from "axios";
import cookie from "js-cookie";
import { getLang } from "../../lang";
import clientRoutes from "../../routes/clientRoutes";
import store from "../../store";

const lang = getLang() ?? process.env.REACT_APP_FALLBACK_LANG;

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/${lang}/`,
});

const logout = () => {
  cookie.remove("token");
  store.dispatch({ type: "SET_LOGOUT" });
  window.location = `${clientRoutes.auth.login}?redirect=${window.location.pathname}`;
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // whatever you want to do with the error
    const { response } = error;
    if (
      response &&
      response.data.message &&
      response.data.message === "Unauthenticated."
    )
      logout();
    return Promise.reject(error);
  }
);

export default api;
