import React, { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../components/helpers/api";
import PageHeader from "../../components/layouts/PageHeader";
import apiRoutes from "../../routes/apiRoutes";

const FAQ = () => {
  const { t } = useTranslation();
  const [faq, setFAQ] = useState([]);

  document.title = t("FAQ");

  useEffect(() => {
    api
      .get(apiRoutes.faq.all)
      .then((response) => setFAQ(response.data.data))
      .catch(() => {});
  }, []);

  return (
    <div>
      <PageHeader title={t("FAQ")} />
      <Container className="mt-5">
        <h4>{t("Here you can find frequently asked questions")}</h4>
        <Accordion>
          {faq.map((thisFaq, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{thisFaq.question}</Accordion.Header>
              <Accordion.Body style={{ whiteSpace: "pre-wrap" }}>
                {thisFaq.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
    </div>
  );
};

export default FAQ;
