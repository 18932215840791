import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { withSnackbar } from "notistack";
import FormInput from "../../components/inputs/FormInput";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import { connect } from "react-redux";
import { storeLoginToken } from "../../components/helpers";
import api from "../../components/helpers/api";
import { withTranslation } from "react-i18next";
import FormCard from "../../components/inputs/FormCard";
import clientRoutes from "../../routes/clientRoutes";

const getQuery = () => {
  return new URLSearchParams(window.location.search);
};

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: getQuery().get("email"),
      code: getQuery().get("code"),
      redirect: getQuery().get("redirect"),
      password: "",
      password_confirmation: "",
      errors: {},
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Reset Password");
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      email: this.state.email,
      code: this.state.code,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };
    return api
      .post(`${apiRoutes.auth.password.reset}`, data)
      .then((res) => {
        storeLoginToken(res.data.access_token);
        this.props.setLogin(res.data.user);
        if (this.state.redirect) {
          window.location = this.state.redirect;
        } else {
          window.location = clientRoutes.home.index;
        }
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { password: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={4} className="mx-auto mt-5 mb-4">
            <FormCard>
              <h3>{t("Reset Password")}</h3>
              <hr />
              <Form>
                <FormInput
                  label={t("New Password")}
                  placeholder={t("New Password")}
                  type="password"
                  name="password"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.password}
                  errorMessage={
                    this.state.errors.password && this.state.errors.password[0]
                  }
                />

                <FormInput
                  label={t("Password Confirmation")}
                  placeholder={t("Password Confirmation")}
                  type="password"
                  name="password_confirmation"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.password_confirmation}
                  errorMessage={
                    this.state.errors.password_confirmation &&
                    this.state.errors.password_confirmation[0]
                  }
                />

                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="d-block w-100"
                  onClick={this.handleForm}
                >
                  {t("Reset Password")}
                </LoadingButton>
              </Form>
            </FormCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
      }),
  };
};
export default withTranslation()(
  withSnackbar(connect(null, mapDispatchToProps)(ResetPassword))
);
