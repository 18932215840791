import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import { roundCurrency } from "../../components/helpers";
import api from "../../components/helpers/api";
import PageHeader from "../../components/layouts/PageHeader";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import { Player, Video, DefaultUi, IconLibrary } from "@vime/react";
import { getLang } from "../../lang";
import clientRoutes from "../../routes/clientRoutes";

const Course = (props) => {
  const courseId = props.match?.params?.id;
  const courseSlug = props.match?.params?.slug;
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [course, setCourse] = useState({});

  useEffect(() => {
    api
      .get(`${apiRoutes.courses.show}/${courseId}/${courseSlug}`)
      .then((response) => {
        const { data } = response.data;
        document.title = data.title;
        setCourse(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          history.push("/404");
        }
      });
  }, [courseId, courseSlug, history]);

  const joinCourse = () => {
    return api
      .post(`${apiRoutes.courses.join}/${courseId}/${courseSlug}`, {})
      .then(() => {
        setCourse({ ...course, has_joined: true });
      })
      .catch(() => {
        enqueueSnackbar(t("Something went wrong"), {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <PageHeader title={course.title} subtitle={course.description} />

      <Container className="mt-3">
        <Row>
          <Col md={9} className="mt-3">
            {course.promo_video && (
              <Row>
                <Col md={{ span: 8, offset: 2 }}>
                  <Player icons="my-library">
                    <Video>
                      <source
                        data-src={
                          `${process.env.REACT_APP_API_URL}/api/${getLang()}/` +
                          apiRoutes.courses.getVideo +
                          "/" +
                          course.id
                        }
                        type="video/mp4"
                      />
                    </Video>

                    <DefaultUi>
                      <IconLibrary
                        name="my-library"
                        resolver={(iconName) => {
                          return `/static/icons/vm-${iconName}.svg`;
                        }}
                      />
                    </DefaultUi>
                  </Player>
                </Col>
              </Row>
            )}

            <Card className="mt-3">
              <Card.Header className="bg-transparent">
                <h3 className="m-0">{t("Description")}</h3>
              </Card.Header>
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={{ __html: course.full_description }}
                />
              </Card.Body>
            </Card>

            <Card className="mt-3">
              <Card.Header className="bg-transparent">
                <h3 className="m-0">{t("Contents")}</h3>
              </Card.Header>

              <Card.Body>
                <ListGroup>
                  {course.lessons?.map((lesson, index) => (
                    <ListGroup.Item
                      key={index}
                      action
                      className="p-3 h5 m-0"
                      disabled={lesson.paid && course.should_pay}
                      href={
                        !(lesson.paid && course.should_pay)
                          ? `${clientRoutes.lessons.show}/${lesson.id}/${lesson.slug}`
                          : "#"
                      }
                    >
                      {lesson.paid && course.should_pay && (
                        <i className="fa fa-lock"></i>
                      )}{" "}
                      {lesson.title}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3} className="order-md-last order-first">
            <Card className="mt-3">
              <Card.Img
                variant="top"
                src={course.image?.image_url ?? "/back-small.jpg"}
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: 250,
                }}
              />
              <Card.Body>
                <Card.Text>{course.title}</Card.Text>
              </Card.Body>
              {course.should_pay && course.price > 0 ? (
                <Link
                  to={`${clientRoutes.courses.pay}/${course.id}/${course.slug}`}
                  className="btn btn-primary btn-lg"
                >
                  {t("Join for")}{" "}
                  {course.price ? `$${course.price}` : t("Free")}
                </Link>
              ) : !course.has_joined ? (
                <LoadingButton size="lg" onClick={joinCourse}>
                  {t("Join for")}{" "}
                  {course.price ? `$${course.price}` : t("Free")}
                </LoadingButton>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Course;
