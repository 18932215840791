import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { isEmptyObj /*, roundCurrency*/ } from "../../components/helpers";
import api from "../../components/helpers/api";
import apiRoutes from "../../routes/apiRoutes";
import { useSnackbar } from "notistack";
import LoadingButton from "../../components/LoadingButton";
import clientRoutes from "../../routes/clientRoutes";

const getQuery = () => {
  return new URLSearchParams(window.location.search);
};

const CoursePay = (props) => {
  const courseId = props.match?.params?.id;
  const courseSlug = props.match?.params?.slug;
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [course, setCourse] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .get(`${apiRoutes.courses.show}/${courseId}/${courseSlug}`)
      .then((response) => {
        const { data } = response.data;
        document.title = data.title;
        if (!data.should_pay) {
          history.push(
            `${clientRoutes.courses.show}/${courseId}/${courseSlug}`
          );
        }
        setCourse(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          history.push("/404");
        }
      });
    const token = getQuery().get("token");
    const PayerID = getQuery().get("PayerID");
    if (token && PayerID) {
      api
        .post(`${apiRoutes.courses.pay}/${courseId}/${courseSlug}`, {
          order_id: getQuery().get("token"),
        })
        .then(() => {
          setIsLoading(false);
          history.push(
            `${clientRoutes.courses.show}/${courseId}/${courseSlug}`
          );
        })
        .catch(() => {
          enqueueSnackbar(t("Something went wrong"), {
            variant: "error",
          });
          setIsLoading(false);
        });
    } else if (token) {
      setIsLoading(false);
      enqueueSnackbar(t("Something went wrong"), {
        variant: "error",
      });
    } else {
      setIsLoading(false);
    }
  }, [courseId, courseSlug, history, enqueueSnackbar, t]);

  const createOrder = () => {
    return api
      .post(`${apiRoutes.courses.createOrder}/${courseId}/${courseSlug}`, {
        redirect_path: history?.location?.pathname,
      })
      .then((response) => {
        const { data } = response;
        const links = data?.result?.links;
        const link = links?.find((link) => link.rel === "approve");
        if (link) {
          window.location = link.href;
        }
      })
      .catch(() => {
        enqueueSnackbar(t("Something went wrong"), {
          variant: "error",
        });
      });
  };

  if (isEmptyObj(course) || isLoading)
    return (
      <Container className="mt-5 text-center">
        <Spinner animation="grow" />
      </Container>
    );
  return (
    <div>
      <Container className="mt-5">
        <Row
          style={{
            boxShadow: "0px 0px 10px rgba(0,0,0,0.4)",
            borderRadius: "10px",
          }}
          className="p-3 align-items-center"
        >
          <Col md={2} className="mt-3">
            <Image
              src={course.image?.card_url ?? "/back.resized.jpg"}
              rounded
              fluid
            />
          </Col>
          <Col md={7} className="mt-3">
            <h2>{course.title}</h2>
            <p className="text-muted">{course.description}</p>
          </Col>
          <Col md={3} className="mt-3">
            <div className="d-grid gap-2">
              <LoadingButton onClick={createOrder}>
                <i className="fab fa-paypal"></i> {t("Pay now with PayPal")} $
                {course.price}
              </LoadingButton>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CoursePay;
