import React from "react";

const FormCard = (props) => {
  return (
    <div style={{ boxShadow: "0px 0px 10px #999" }} className="p-3">
      {props.children}
    </div>
  );
};

export default FormCard;
