import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import CategoriesReducer from "./CategoriesReducer";
import SocialMediaReducer from "./SocialMediaReducer";

const AllReducers = combineReducers({
  auth: AuthReducer,
  socialMedia: SocialMediaReducer,
  categories: CategoriesReducer,
});

export default AllReducers;
