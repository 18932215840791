import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import apiRoutes from "../../routes/apiRoutes";
import api from "../helpers/api";
import Carousel from "react-multi-carousel";
import clientRoutes from "../../routes/clientRoutes";

const TopCoursesSection = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    api
      .get(apiRoutes.courses.home)
      .then((response) => setCourses(response.data.data))
      .catch(() => {});
  }, []);

  const goToCourse = (courses) => {
    history.push(`${clientRoutes.courses.show}/${courses.id}/${courses.slug}`);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Container className="mt-5">
      <h1>{t("Explore Top Courses")}</h1>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        containerClass="direction-ltr"
        transitionDuration={500}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {courses.map((courses, idx) => (
          <Card className="bg-dark text-white me-3" key={idx}>
            <Card.Img
              src={courses.image?.card_url ?? "/back-small.jpg"}
              alt={courses.title}
              style={{
                objectFit: "cover",
                width: "100%",
                height: 250,
              }}
            />
            <Card.ImgOverlay
              className="main-headlines"
              onClick={() => goToCourse(courses)}
            >
              <h4>{courses.title}</h4>
            </Card.ImgOverlay>
          </Card>
        ))}
      </Carousel>
    </Container>
  );
};

export default TopCoursesSection;
