import React from "react";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const ShareButtons = (props) => {
  const { body, tags } = props;
  return (
    <React.Fragment>
      <EmailShareButton className="me-1" url={window.location.href} body={body}>
        <EmailIcon size={45} />
      </EmailShareButton>
      <FacebookShareButton className="me-1" url={window.location.href} quote={body}>
        <FacebookIcon size={45} />
      </FacebookShareButton>
      <TelegramShareButton className="me-1" url={window.location.href} title={body}>
        <TelegramIcon size={45} />
      </TelegramShareButton>
      <TwitterShareButton
        className="me-1" url={window.location.href}
        title={body}
        hashtags={tags}
      >
        <TwitterIcon size={45} />
      </TwitterShareButton>
      <WhatsappShareButton className="me-1" url={window.location.href} title={body}>
        <WhatsappIcon size={45} />
      </WhatsappShareButton>
    </React.Fragment>
  );
};

export default ShareButtons;
