const socialmedia = [
  { name: "facebook", icon: "fab fa-facebook", color: "#1877F2" },
  { name: "whatsapp", icon: "fab fa-whatsapp", color: "#25D366" },
  { name: "youtube", icon: "fab fa-youtube", color: "#CD201F" },
  { name: "instagram", icon: "fab fa-instagram", color: "#E4405F" },
  { name: "twitter", icon: "fab fa-twitter", color: "#1DA1F2" },
  { name: "linkedin", icon: "fab fa-linkedin", color: "#0A66C2" },
  { name: "email", icon: "fa fa-at", color: "#999" },
  { name: "snapchat", icon: "fab fa-snapchat", color: "#FFFC00" },
  { name: "soundcloud", icon: "fab fa-soundcloud", color: "#ff8800" },
  { name: "telegram", icon: "fab fa-telegram", color: "#0088cc" },
  { name: "patreon", icon: "fab fa-patreon", color: "#f96854" },
];

export default socialmedia;
