import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { withSnackbar } from "notistack";
import FormInput from "../../components/inputs/FormInput";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import api from "../../components/helpers/api";
import { withTranslation } from "react-i18next";
import FormCard from "../../components/inputs/FormCard";
import clientRoutes from "../../routes/clientRoutes";

const getQuery = () => {
  return new URLSearchParams(window.location.search);
};

class CheckPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: getQuery().get("email"),
      redirect: getQuery().get("redirect"),
      code: "",
      errors: {},
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Check Password");
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      email: this.state.email,
      code: this.state.code,
    };
    return api
      .post(`${apiRoutes.auth.password.check}`, data)
      .then((res) => {
        this.props.history.push(
          `${clientRoutes.auth.resetPassword}${
            this.state.redirect ? "&" : "?"
          }email=${this.state.email}&code=${this.state.code}`
        );
        this.props.enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { code: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={4} className="mx-auto mt-5 mb-4">
            <FormCard>
              <h3>{t("Check Password")}</h3>
              <hr />
              <Form>
                <FormInput
                  label={t("Code")}
                  placeholder={t("Code")}
                  type="text"
                  labelClassName="required"
                  name="code"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.code}
                  errorMessage={
                    this.state.errors.code && this.state.errors.code[0]
                  }
                />

                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="d-block w-100"
                  onClick={this.handleForm}
                >
                  {t("Check Password Code")}
                </LoadingButton>
              </Form>
            </FormCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(withSnackbar(CheckPassword));
