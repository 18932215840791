import React from "react";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import clientRoutes from "../../routes/clientRoutes";

const IntroSection = (props) => {
  const { t } = useTranslation();
  const { loggedIn, categories } = props;
  return (
    <div>
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/back.resized.jpg"
            alt="First slide"
            style={{ minHeight: 500, objectFit: "cover" }}
          />
          <Carousel.Caption
            style={{
              backgroundColor: "rgba(10,10,10,50%)",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
            className="p-3"
          >
            <div>
              <h1>{t("Learn Arabic Easily")}</h1>
              <p className="m-0">
                {t(
                  "Learn the Arabic language in the easiest way and shortest time."
                )}
              </p>
              <p className="m-0">
                {t(
                  "We will accompany you in your learning journey from the very beginning and help you reach your goals."
                )}
              </p>
              <p className="m-0">
                {t("Video Courses and Online Classes are available.")}
              </p>
              {!loggedIn && (
                <Link
                  className="btn btn-secondary"
                  to={clientRoutes.auth.login}
                >
                  {t("Register Now")}
                </Link>
              )}
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        {categories.map((category, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={category.image?.image_url ?? "/back.resized.jpg"}
              alt="First slide"
              style={{ minHeight: 500, objectFit: "cover" }}
            />
            <Carousel.Caption
              style={{
                backgroundColor: "rgba(10,10,10,50%)",
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                alignItems: "center",
                height: "100%",
                justifyContent: "center",
              }}
              className="p-3"
            >
              <div>
                <h1>{category.title}</h1>
                <p style={{ whiteSpace: "pre-wrap" }}>{category.description}</p>
                <Link
                  className="btn btn-secondary"
                  to={`${clientRoutes.categories.show}/${category.id}/${category.slug}`}
                >
                  {t("Show more")}
                </Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    categories: state.categories.nav,
  };
};

export default connect(mapStateToProps)(IntroSection);
