import React from "react";
import { Form } from "react-bootstrap";

const FormInput = (props) => {
  const {
    label,
    onChange,
    isInvalid,
    labelClassName,
    errorMessage,
    value,
    name,
    helpText,
  } = props;
  return (
    <Form.Group className="mb-3">
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <Form.Control
        {...props}
        name={name}
        onChange={onChange}
        isInvalid={isInvalid}
        value={value}
      />
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormInput;
