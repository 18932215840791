import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import api from "../../components/helpers/api";
import LessonContainer from "../../components/lessons/LessonContainer";
import apiRoutes from "../../routes/apiRoutes";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { getLang } from "../../lang";
import { FileTypeTitle } from "../../components/lessons/LessonCollapse";

const LessonAudio = (props) => {
  const lessonId = props.match?.params?.id;
  const lessonSlug = props.match?.params?.slug;
  const audioId = props.match?.params?.audioId;
  const history = useHistory();

  const [lesson, setLesson] = useState({});
  const [audio, setAudio] = useState({});

  useEffect(() => {
    api
      .get(`${apiRoutes.lessonAudios.show}/${lessonId}/${audioId}`)
      .then((response) => setAudio(response.data.data))
      .catch(() => {});
  }, [lessonId, lessonSlug, audioId, history]);

  return (
    <div>
      <LessonContainer
        lesson={lesson}
        lessonSlug={lessonSlug}
        setLesson={setLesson}
        lessonId={lessonId}
        fileId={audioId}
        fileType="audio"
      >
        <h4 className="mt-3">
          <FileTypeTitle type="audio" /> {audio.title}
        </h4>
        <div style={{ direction: "ltr" }}>
          <AudioPlayer
            src={
              `${process.env.REACT_APP_API_URL}/api/${getLang()}/` +
              apiRoutes.lessonAudios.showAudio +
              "/" +
              audioId
            }
            showJumpControls
            showFilledVolume
            autoPlayAfterSrcChange={false}
          />
        </div>
      </LessonContainer>
    </div>
  );
};

export default LessonAudio;
