import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import FormInput from "../../components/inputs/FormInput";
import FormSelect from "../../components/inputs/FormSelect";
import { storeLoginToken } from "../../components/helpers";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import countries from "../../config/countries";
import api from "../../components/helpers/api";
import { withTranslation } from "react-i18next";
import FormCard from "../../components/inputs/FormCard";
import { Link } from "react-router-dom";
import clientRoutes from "../../routes/clientRoutes";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      birth_date: "2000-01-01",
      password: "",
      password_confirmation: "",
      country: "",
      mobile_number: "",
      gender: "",
      lang: "",
      errors: {},
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Register");
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      birth_date: this.state.birth_date,
      lang: this.state.lang,
      country: this.state.country,
      gender: this.state.gender,
      mobile_number: this.state.mobile_number,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    };
    return api
      .post(`${apiRoutes.auth.register}`, data)
      .then((res) => {
        storeLoginToken(res.data.access_token, this.state.remember);
        // dispatch action
        this.props.setLogin(res.data.user);
        window.location = clientRoutes.auth.verifyEmail;
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { email: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={4} className="mx-auto mt-5 mb-4">
            <FormCard>
              <h3>{t("Register")}</h3>
              <hr />
              <Form>
                <Row>
                  <Col>
                    <FormInput
                      label={t("First Name")}
                      placeholder={t("First Name")}
                      type="text"
                      name="first_name"
                      autoFocus
                      labelClassName="required"
                      onChange={this.handleInput}
                      isInvalid={this.state.errors.first_name}
                      errorMessage={
                        this.state.errors.first_name &&
                        this.state.errors.first_name[0]
                      }
                    />
                  </Col>
                  <Col>
                    <FormInput
                      label={t("Last Name")}
                      placeholder={t("Last Name")}
                      type="text"
                      labelClassName="required"
                      name="last_name"
                      onChange={this.handleInput}
                      isInvalid={this.state.errors.last_name}
                      errorMessage={
                        this.state.errors.last_name &&
                        this.state.errors.last_name[0]
                      }
                    />
                  </Col>
                </Row>

                <FormInput
                  label={t("Email address")}
                  placeholder={t("Email address")}
                  type="email"
                  name="email"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.email}
                  errorMessage={
                    this.state.errors.email && this.state.errors.email[0]
                  }
                />

                <FormSelect
                  label={t("Country")}
                  name="country"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.country}
                  errorMessage={
                    this.state.errors.country && this.state.errors.country[0]
                  }
                >
                  {countries.map((country, idx) => (
                    <option value={country.label} key={idx}>
                      {country.label}
                    </option>
                  ))}
                </FormSelect>

                <FormInput
                  label={t("Language")}
                  placeholder={t("Language")}
                  type="text"
                  name="lang"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.lang}
                  errorMessage={
                    this.state.errors.lang && this.state.errors.lang[0]
                  }
                />

                <FormSelect
                  label={t("Gender")}
                  name="gender"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.gender}
                  errorMessage={
                    this.state.errors.gender && this.state.errors.gender[0]
                  }
                >
                  <option value="male">{t("Male")}</option>
                  <option value="female">{t("Female")}</option>
                </FormSelect>

                <FormInput
                  label={t("Birth date")}
                  placeholder={t("Birth date")}
                  type="date"
                  name="birth_date"
                  labelClassName="required"
                  value={this.state.birth_date}
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.birth_date}
                  errorMessage={
                    this.state.errors.birth_date &&
                    this.state.errors.birth_date[0]
                  }
                />

                <FormInput
                  label={t("Mobile number")}
                  placeholder={t("Mobile number")}
                  type="text"
                  name="mobile_number"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.mobile_number}
                  errorMessage={
                    this.state.errors.mobile_number &&
                    this.state.errors.mobile_number[0]
                  }
                />

                <FormInput
                  label={t("Password")}
                  placeholder={t("Password")}
                  type="password"
                  name="password"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.password}
                  errorMessage={
                    this.state.errors.password && this.state.errors.password[0]
                  }
                />

                <FormInput
                  label={t("Password Confirmation")}
                  placeholder={t("Password Confirmation")}
                  type="password"
                  name="password_confirmation"
                  labelClassName="required"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.password_confirmation}
                  errorMessage={
                    this.state.errors.password_confirmation &&
                    this.state.errors.password_confirmation[0]
                  }
                />

                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="d-block w-100"
                  onClick={this.handleForm}
                >
                  {t("Register")}
                </LoadingButton>

                <div className="mt-5">{t("Already have an account")}</div>
                <Link
                  to={clientRoutes.auth.login}
                  className="btn d-block btn-outline-primary mt-3"
                >
                  {t("Login")}
                </Link>
              </Form>
            </FormCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
      }),
  };
};
export default withTranslation()(connect(null, mapDispatchToProps)(Register));
