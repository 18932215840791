import { Avatar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import BlogComments from "../../components/blog/BlogComments";
import RecentArticles from "../../components/blog/RecentArticles";
import ShareButtons from "../../components/blog/ShareButtons";
import api from "../../components/helpers/api";
import FormCard from "../../components/inputs/FormCard";
import PageHeader from "../../components/layouts/PageHeader";
import apiRoutes from "../../routes/apiRoutes";

const ShowArticle = (props) => {
  const articleId = props.match?.params?.id;
  const articleSlug = props.match?.params?.slug;
  const history = useHistory();

  const [article, setArticle] = useState({});

  useEffect(() => {
    api
      .get(`${apiRoutes.blog.show}/${articleId}/${articleSlug}`)
      .then((response) => {
        const { data } = response.data;
        document.title = data.title;
        setArticle(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          history.push("/404");
        }
      });
  }, [articleId, articleSlug, history]);

  return (
    <div>
      <PageHeader
        title={article.title}
        subtitle={article.headline}
        cover={article.image?.image_url}
      />
      <Container className="mt-3">
        <Row>
          <Col md={9}>
            <FormCard>
              <div class="d-flex justify-content-center">
                <ShareButtons
                  body={article.title}
                  tags={article?.tags?.split(",")}
                />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: article.contents }}
                className="mt-5"
                dir="auto"
              />

              <FormCard>
                <div className="d-flex">
                  <div className="me-3">
                    <Avatar src={article.author_image?.thumb_url}>
                      {article.author_name?.charAt(0).toUpperCase()}
                    </Avatar>
                  </div>
                  <div>
                    <div className="fs-5">{article.author_name}</div>
                    <div
                      className="text-muted"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {article.author_details}
                    </div>
                  </div>
                </div>
              </FormCard>
            </FormCard>

            <div className="mt-3">
              <FormCard>
                <BlogComments blogId={articleId} />
              </FormCard>
            </div>
          </Col>
          <Col md={3}>
            <FormCard>
              <RecentArticles />
            </FormCard>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShowArticle;
