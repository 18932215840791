import React, { useEffect } from "react";
import { Switch, Route as ReactRoute } from "react-router";
import { useHistory, useLocation } from "react-router";
import Home from "../pages/Home";
import NotFound from "../components/errors/NotFound";
import Login from "../pages/Auth/Login";
import Route from "./Route";
import GuestRoute from "./GuestRoute";
import Register from "../pages/Auth/Register";
import AuthRoute from "./AuthRoute";
import VerifyEmail from "../pages/Auth/VerifyEmail";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import CheckPassword from "../pages/Auth/CheckPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import Category from "../pages/categories/Category";
import Course from "../pages/courses/Course";
import Lesson from "../pages/lessons/Lesson";
import LessonFile from "../pages/lessons/LessonFile";
import LessonVideo from "../pages/lessons/LessonVideo";
import LessonAudio from "../pages/lessons/LessonAudio";
import Categories from "../pages/categories/Categories";
import CoursePay from "../pages/courses/CoursePay";
import Profile from "../pages/user/Profile";
import MyCourses from "../pages/user/MyCourses";
import AboutUs from "../pages/policies/AboutUs";
import PrivacyPolicy from "../pages/policies/PrivacyPolicy";
import TermsAndConditions from "../pages/policies/TermsAndConditions";
import FAQ from "../pages/policies/FAQ";
import Articles from "../pages/blog/Articles";
import ShowArticle from "../pages/blog/ShowArticle";
import languages from "../config/languages";
import { getLang } from "../lang";

const Router = () => {
  const history = useHistory();
  const location = useLocation();
  const currentLang = getLang() ?? "en";

  useEffect(() => {
    const locales = languages.map((language) => language.lang);
    const pathChuncks = location.pathname
      .split("/")
      .filter((chunck) => chunck !== "");

    if (pathChuncks.length) {
      if (locales.includes(pathChuncks[0])) {
        if (pathChuncks[0] !== currentLang) {
          pathChuncks[0] = currentLang;
          history.push(`/${pathChuncks.join("/")}`);
        }
      } else {
        history.push(`/${currentLang}${location.pathname}${location.search}`);
      }
    } else {
      history.push(currentLang);
    }
  }, [location, history, currentLang]);

  return (
    <Switch>
      <Route exact path={`/`} component={Home} />
      <Route exact path={`/:locale/`} component={Home} />

      <GuestRoute exact path={`/:locale/login`} component={Login} />
      <GuestRoute exact path={`/:locale/register`} component={Register} />
      <GuestRoute
        exact
        path="/:locale/forgot/password"
        component={ForgetPassword}
      />
      <GuestRoute
        exact
        path="/:locale/check/password"
        component={CheckPassword}
      />
      <GuestRoute
        exact
        path="/:locale/reset/password"
        component={ResetPassword}
      />

      <AuthRoute exact path="/:locale/verify/email" component={VerifyEmail} />
      <AuthRoute exact path="/:locale/profile" component={Profile} />
      <AuthRoute exact path="/:locale/mycourses" component={MyCourses} />

      <Route exact path="/:locale/categories" component={Categories} />
      <Route exact path={`/:locale/category/:id/:slug`} component={Category} />
      <Route exact path="/:locale/courses/:id/:slug" component={Course} />
      <AuthRoute
        exact
        path="/:locale/courses/pay/:id/:slug"
        component={CoursePay}
      />

      <AuthRoute exact path="/:locale/lessons/:id/:slug" component={Lesson} />
      <AuthRoute
        exact
        path="/:locale/lessons/:id/:slug/files/:fileId"
        component={LessonFile}
      />
      <AuthRoute
        exact
        path="/:locale/lessons/:id/:slug/videos/:videoId"
        component={LessonVideo}
      />
      <AuthRoute
        exact
        path="/:locale/lessons/:id/:slug/audios/:audioId"
        component={LessonAudio}
      />

      <Route exact path="/:locale/articles" component={Articles} />
      <Route exact path="/:locale/articles/:id/:slug" component={ShowArticle} />

      <Route exact path="/:locale/aboutus" component={AboutUs} />
      <Route exact path="/:locale/privacy" component={PrivacyPolicy} />
      <Route
        exact
        path="/:locale/terms_and_conditions"
        component={TermsAndConditions}
      />
      <Route exact path="/:locale/faq" component={FAQ} />
      {/* <ReactRoute component={NotFound} /> */}
    </Switch>
  );
};

export default Router;
