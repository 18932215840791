import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { withSnackbar } from "notistack";
import FormInput from "../../components/inputs/FormInput";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import api from "../../components/helpers/api";
import { withTranslation } from "react-i18next";
import FormCard from "../../components/inputs/FormCard";
import clientRoutes from "../../routes/clientRoutes";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
      redirect: new URLSearchParams(this.props.location.search).get("redirect"),
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Forgot Password");
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      email: this.state.email,
    };
    return api
      .post(`${apiRoutes.auth.password.forget}`, data)
      .then((res) => {
        this.props.history.push(
          `${clientRoutes.auth.checkPassword}${
            this.state.redirect ? "&" : "?"
          }email=${this.state.email}`
        );
        this.props.enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { email: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={4} className="mx-auto mt-5 mb-4">
            <FormCard>
              <h3>{t("Forgot Password")}</h3>
              <hr />
              <Form>
                <FormInput
                  label={t("Email address")}
                  placeholder={t("Email address")}
                  type="email"
                  labelClassName="required"
                  name="email"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.email}
                  errorMessage={
                    this.state.errors.email && this.state.errors.email[0]
                  }
                />

                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="d-block w-100"
                  onClick={this.handleForm}
                >
                  {t("Reset Password")}
                </LoadingButton>
              </Form>
            </FormCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation()(withSnackbar(ForgetPassword));
