import React, { useEffect } from "react";
import { Container, NavDropdown, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "js-cookie";
import { withSnackbar } from "notistack";
import Logo from "../components/Logo";
import ContactFab from "./ContactFab";
import Footer from "./Footer";
import LoadingButton from "../components/LoadingButton";
import apiRoutes from "../routes/apiRoutes";
import api from "../components/helpers/api";
import { getSocialUrl } from "../components/helpers";
import LanguageNav from "./LanguageNav";
import clientRoutes from "../routes/clientRoutes";

const Layout = (props) => {
  const { t } = useTranslation();
  const { user, loggedIn, socialMedia, categories, setNavCategories } = props;

  useEffect(() => {
    api
      .get(apiRoutes.home.navCategories)
      .then((response) => {
        const { data } = response.data;
        setNavCategories(data);
      })
      .catch(() => {});
  }, [setNavCategories]);

  const handleLogout = () => {
    return api
      .post(apiRoutes.auth.logout)
      .then(() => {
        cookie.remove("token");
        props.logout();
        window.location = clientRoutes.home.index;
      })
      .catch(() => {
        props.enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      });
  };

  const getPatreonAction = () => {
    const social = socialMedia.find(
      (thisSocial) => thisSocial.type === "patreon"
    );
    if (social) {
      return (
        <Nav.Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.open(getSocialUrl(social));
            }}
            className="btn btn-outline-patreon"
          >
            <i className="fab fa-patreon" /> {social.title}
          </Link>
        </Nav.Link>
      );
    }
    return null;
  };

  return (
    <div>
      <LanguageNav />
      <NavDropdown.Divider />
      <Navbar
        collapseOnSelect
        expand="lg"
        // bg="primary"
        // variant="dark"
        style={{
          boxShadow: "0px 17px 10px -10px rgba(0,0,0,0.4)",
          fontWeight: "bold",
        }}
      >
        <Container>
          <Navbar.Brand>
            <NavLink
              to={clientRoutes.home.index}
              className="text-reset text-decoration-none"
            >
              <Logo />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link>
                <NavLink
                  to={clientRoutes.home.index}
                  className="text-reset text-decoration-none"
                >
                  {t("Home")}
                </NavLink>
              </Nav.Link>
              {categories.map((category) => (
                <Nav.Link key={category.id}>
                  <NavLink
                    to={`${clientRoutes.categories.show}/${category.id}/${category.slug}`}
                    className="text-reset text-decoration-none"
                  >
                    {category.title}
                  </NavLink>
                </Nav.Link>
              ))}
              <Nav.Link href={clientRoutes.articles.index}>
                {t("Blog")}
              </Nav.Link>
              <Nav.Link>
                <NavLink
                  to={clientRoutes.sitePolicies.aboutus}
                  className="text-reset text-decoration-none"
                >
                  {t("About Us")}
                </NavLink>
              </Nav.Link>
            </Nav>
            <Nav>
              {loggedIn ? (
                <Nav.Link>
                  <NavDropdown title={user.name} id="basic-nav-dropdown">
                    <div className="p-3 text-muted">{user.email}</div>
                    <NavDropdown.Item>
                      <Link
                        to={clientRoutes.profile.index}
                        className="text-reset text-decoration-none"
                      >
                        {t("Profile")}
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>
                      <LoadingButton
                        variant="outline-danger"
                        className="w-100"
                        onClick={handleLogout}
                      >
                        {t("Logout")}
                      </LoadingButton>
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav.Link>
              ) : (
                <>
                  <Nav.Link>
                    <Link
                      to={clientRoutes.auth.login}
                      className="btn btn-outline-primary"
                    >
                      {t("Login")}
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to={clientRoutes.auth.register}
                      className="btn btn-primary"
                    >
                      {t("Register")}
                    </Link>
                  </Nav.Link>
                </>
              )}
              {getPatreonAction()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {props.children}
      <ContactFab />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loggedIn: state.auth.loggedIn,
    socialMedia: state.socialMedia.data,
    categories: state.categories.nav,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () =>
      dispatch({
        type: "SET_LOGOUT",
      }),
    setNavCategories: (categories) =>
      dispatch({
        type: "SET_CATEGORIES_NAV_DATA",
        payload: categories,
      }),
  };
};

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Layout)
);
