import React from "react";
import { Form } from "react-bootstrap";

const FormSelect = (props) => {
  const { label, onChange, labelClassName, isInvalid, errorMessage } = props;
  return (
    <Form.Group className="mb-3">
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <Form.Select
        {...props}
        onChange={onChange}
        isInvalid={isInvalid}
        aria-label={label}
      >
        <option>{label}</option>
        {props.children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormSelect;
