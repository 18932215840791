import React, { Fragment } from "react";
import { Container, Navbar, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import languages from "../config/languages";
import { getLang, storeLang } from "../lang";

const LanguageNav = () => {
  const history = useHistory();
  const { locale } = useParams();
  const currentLanguage = getLang();

  const handleLanguage = (lang) => {
    history.push(history.location.pathname.replace(locale, lang.lang));
    storeLang(lang.lang);
    history.go(0);
  };
  return (
    <Navbar expand="sm">
      <Container>
        <div>
          {languages.map((lang, idx) => (
            <Fragment key={idx}>
              <Button
                variant={currentLanguage === lang.lang ? "secondary" : "light"}
                disabled={currentLanguage === lang.lang}
                onClick={() => handleLanguage(lang)}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${lang.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${lang.code.toLowerCase()}.png 2x`}
                  alt=""
                />{" "}
                {lang.language}
              </Button>{" "}
              |
            </Fragment>
          ))}
        </div>
      </Container>
    </Navbar>
  );
};

export default LanguageNav;
