import React, { useEffect, useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LessonSideBar from "./LessonSideBar";

const LessonOffcanvas = (props) => {
  const [show, setShow] = useState(false);
  const [showButtonInfo, setShowButtonInfo] = useState(true);
  const { t } = useTranslation();
  const { lessons, lessonId, fileId, fileType } = props;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButtonInfo(false);
    }, 3000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        onClick={handleShow}
        variant="primary"
        style={{
          position: "fixed",
          top: 250,
          right: 0,
          borderRadius: "20px 0 0 20px",
          boxShadow: "0px 17px 10px -10px rgba(0,0,0,0.4)",
        }}
        className="d-lg-none"
      >
        <i className="fas fa-book-open"></i>
        {showButtonInfo && `  ${t("View lessons")}`}
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("Lessons")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <LessonSideBar
            lessons={lessons}
            lessonId={lessonId}
            fileId={fileId}
            fileType={fileType}
            closeSideBar={() => setShow(false)}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LessonOffcanvas;
