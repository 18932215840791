import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import api from "../../components/helpers/api";
import LessonContainer from "../../components/lessons/LessonContainer";
import apiRoutes from "../../routes/apiRoutes";
import { Player, Video, DefaultUi, IconLibrary } from "@vime/react";
import { getLang } from "../../lang";
import { FileTypeTitle } from "../../components/lessons/LessonCollapse";

const LessonVideo = (props) => {
  const lessonId = props.match?.params?.id;
  const lessonSlug = props.match?.params?.slug;
  const videoId = props.match?.params?.videoId;
  const history = useHistory();

  const [lesson, setLesson] = useState({});
  const [video, setVideo] = useState({});

  useEffect(() => {
    api
      .get(`${apiRoutes.lessonVideos.show}/${lessonId}/${videoId}`)
      .then((response) => setVideo(response.data.data))
      .catch(() => {});
  }, [lessonId, lessonSlug, videoId, history]);

  return (
    <div>
      <LessonContainer
        lesson={lesson}
        lessonSlug={lessonSlug}
        setLesson={setLesson}
        lessonId={lessonId}
        fileId={videoId}
        fileType="video"
      >
        <h4 className="mt-3">
          <FileTypeTitle type="video" /> {video.title}
        </h4>
        <Player icons="my-library">
          <Video>
            <source
              data-src={
                `${process.env.REACT_APP_API_URL}/api/${getLang()}/` +
                apiRoutes.lessonVideos.showVideo +
                "/" +
                videoId
              }
              // type="video/mp4"
            />
          </Video>

          <DefaultUi>
            <IconLibrary
              name="my-library"
              resolver={(iconName) => {
                return `/static/icons/vm-${iconName}.svg`;
              }}
            />
          </DefaultUi>
        </Player>
      </LessonContainer>
    </div>
  );
};

export default LessonVideo;
