const apiRoutes = {
  auth: {
    login: "user/login",
    register: "user/register",
    verify: "user/verify",
    resend: "user/resend/verification/code",
    logout: "user/logout",
    me: "user/me",
    password: {
      forget: "user/forget/password",
      check: "user/check/password/code",
      reset: "user/reset/password",
    },
  },
  home: {
    navCategories: "user/categories/nav",
    latest: "user/categories/latest",
    counts: "user/home/counts",
  },
  categories: {
    all: "user/categories/all",
    show: "user/categories/show",
    getVideo: "user/categories/video/show",
  },
  courses: {
    show: "user/courses/show",
    latest: "user/courses/latest",
    home: "user/courses/home",
    pay: "user/courses/pay",
    join: "user/courses/join",
    createOrder: "user/courses/paypal/create",
    getVideo: "user/courses/video/show",
  },
  lessons: {
    show: "user/lessons/show",
  },
  lessonFiles: {
    show: "user/lessons/files/show",
    showPDF: "user/lessons/files/pdf/show",
  },
  lessonVideos: {
    show: "user/lessons/videos/show",
    showVideo: "user/lessons/videos/show",
  },
  lessonAudios: {
    show: "user/lessons/audios/show",
    showAudio: "user/lessons/audios/show",
  },
  lessonComments: {
    show: "user/lessons/comments",
    store: "user/lessons/comments",
  },
  profile: {
    updateInfo: "user/profile/info/update",
    updatePassword: "user/profile/password/update",
    courses: "user/profile/courses",
  },
  contactus: {
    send: "user/contactus/send",
    socialMedia: "user/social_media/all",
  },
  sitePolicies: {
    aboutUs: "user/about_us",
    privacyPolicy: "user/privacy_policy",
    termsAndConditions: "user/terms_and_conditions",
  },
  faq: {
    all: "user/faq",
  },
  blog: {
    all: "user/blog/all",
    recent: "user/blog/recent",
    show: "user/blog/show",
    storeComment: "user/blog/comments",
    showComment: "user/blog/comments",
  },
};

export default apiRoutes;
