import React, { useEffect } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSocialUrl } from "../components/helpers";
import api from "../components/helpers/api";
import socialmedia from "../config/socialmedia";
import apiRoutes from "../routes/apiRoutes";
import clientRoutes from "../routes/clientRoutes";

const Footer = (props) => {
  const { t } = useTranslation();
  const { socialMedia } = props;

  useEffect(() => {
    api
      .get(apiRoutes.contactus.socialMedia)
      .then((response) => props.setSocialMediaData(response.data.data))
      .catch(() => {});
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="bg-dark text-white mt-5 pt-5 pb-5">
        <Container>
          <Row>
            <Col className="mt-3" md={6}>
              <h2>{t("Contact Us")}</h2>
              <p>{t("You can always reach us on")}</p>
              {socialMedia?.map((social, index) => {
                const socialData = socialmedia.find(
                  (thisSocial) => thisSocial.name === social.type
                );
                return (
                  <OverlayTrigger
                    key={index}
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-${index}`} className="red-tooltip">
                        {social.title}
                      </Tooltip>
                    }
                  >
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(getSocialUrl(social));
                      }}
                      className={`btn btn-${socialData.name} me-3 mt-1 text-white`}
                      style={{
                        borderRadius: "100%",
                        width: 46,
                        height: 46,
                        lineHeight: "40px",
                      }}
                    >
                      <i className={`${socialData.icon} fa-lg`}></i>
                      {/* {social.title} */}
                    </Link>
                  </OverlayTrigger>
                );
              })}
            </Col>
            {/* <Col className="mt-3">
              <h2>{t("Featured Topic")}</h2>
              <ul className="list-unstyled">
                <li className="pb-3">
                  <Link to="#" className="text-white text-decoration-none">
                    First Topic
                  </Link>
                </li>
                <li className="pb-3">
                  <Link to="#" className="text-white text-decoration-none">
                    Second Topic
                  </Link>
                </li>
                <li className="pb-3">
                  <Link to="#" className="text-white text-decoration-none">
                    Thirs Topic
                  </Link>
                </li>
              </ul>
            </Col> */}
            <Col className="mt-3" md={6}>
              <h2>{t("Learn about us")}</h2>
              <ul className="list-unstyled">
                <li className="pb-3">
                  <Link
                    to={clientRoutes.sitePolicies.aboutus}
                    className="text-white text-decoration-none"
                  >
                    {t("About Us")}
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    to={clientRoutes.sitePolicies.privacy}
                    className="text-white text-decoration-none"
                  >
                    {t("Privacy policy")}
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    to={clientRoutes.sitePolicies.terms}
                    className="text-white text-decoration-none"
                  >
                    {t("Terms and conditions")}
                  </Link>
                </li>
                <li className="pb-3">
                  <Link
                    to={clientRoutes.sitePolicies.faq}
                    className="text-white text-decoration-none"
                  >
                    {t("FAQ")}
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ padding: 20, textAlign: "center" }}>
        جميع الحقوق محفوظة Arabic-Tayseer.com © 2021. Designed by Awesome
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    socialMedia: state.socialMedia.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSocialMediaData: (socialMedia) => {
      dispatch({ type: "SET_SOCIAL_MEDIA_DATA", payload: socialMedia });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
