import Router from "./routes/Router";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLang, storeLang } from "./lang";
import "moment/locale/ar";
import "moment/locale/ru";
import "moment/locale/ky";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/uz";
import "moment/locale/bg";
import moment from "moment";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import languages from "./config/languages";
const en = require("./lang/en.json");
const ar = require("./lang/ar.json");
const ru = require("./lang/ru.json");
const ky = require("./lang/ky.json");
const nl = require("./lang/nl.json");
const fr = require("./lang/fr.json");
const uz = require("./lang/uz.json");
const bg = require("./lang/bg.json");

const lang = getLang();
moment.locale(lang);
if (lang === "ar") {
  import("./custom-rtl.scss");
} else {
  import("./custom.scss");
}

const App = () => {
  const location = useLocation();
  const [locale, setLocale] = useState(lang);

  useEffect(() => {
    const locales = languages.map((language) => language.lang);
    const pathChuncks = location.pathname
      .split("/")
      .filter((chunck) => chunck !== "");
    if (
      pathChuncks.length &&
      pathChuncks[0] !== lang &&
      locales.includes(pathChuncks[0])
    ) {
      setLocale(pathChuncks[0]);
      storeLang(pathChuncks[0]);
    }
    // eslint-disable-next-line
  }, []);

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: {
          translation: en,
        },
        ar: {
          translation: ar,
        },
        ru: {
          translation: ru,
        },
        ky: {
          translation: ky,
        },
        fr: {
          translation: fr,
        },
        nl: {
          translation: nl,
        },
        uz: {
          translation: uz,
        },
        bg: {
          translation: bg,
        },
      },
      lng: locale, // if you're using a language detector, do not define the lng option
      fallbackLng: process.env.REACT_APP_FALLBACK_LANG,

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
  return <Router />;
};

export default App;
