import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import apiRoutes from "../../routes/apiRoutes";
import clientRoutes from "../../routes/clientRoutes";
import api from "../helpers/api";

const RecentArticles = () => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    api
      .get(`${apiRoutes.blog.recent}`)
      .then((response) => {
        const { data } = response.data;
        setArticles(data);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <h3>{t("Recent Articles")}</h3>
      {articles.map((article) => (
        <div className="d-flex mt-3" key={article.id}>
          <div className="me-3">
            <Image
              src={article.image?.thumb_url}
              alt={article.title}
              style={{ maxWidth: 70 }}
            />
          </div>
          <div>
            <div className="fs-5">{article.title}</div>
            <div className="text-muted">
              <Link
                to={`${clientRoutes.articles.show}/${article.id}/${article.slug}`}
              >
                {t("Read more")}&gt;&gt;
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RecentArticles;
