import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { fNumber } from "../utils/formatNumber";
import { useTranslation } from "react-i18next";
import api from "../helpers/api";
import apiRoutes from "../../routes/apiRoutes";

const StatsSection = () => {
  const { t } = useTranslation();
  const [visitors, setVisitors] = useState(0);

  useEffect(() => {
    api
      .get(apiRoutes.home.counts)
      .then((response) => setVisitors(response.data.visitors))
      .catch(() => {});
  }, []);

  return (
    <div>
      <Container className="mt-5 text-center text-white">
        <Row>
          <Col md="4">
            <div className="bg-success bg-gradient m-3 p-5 rounded-3">
              <div className="mb-3">
                <div
                  className="d-inline-block p-3 rounded-circle shadow-lg bg-success bg-gradient"
                  style={{ width: 80, height: 80, lineHeight: "70px" }}
                >
                  <i className="fa fa-book fs-1" aria-hidden="true"></i>
                </div>
              </div>
              <h1 className="counting">{fNumber(30)}</h1>
              <h5>{t("Books")}</h5>
            </div>
          </Col>
          <Col md="4">
            <div className="bg-primary bg-gradient m-3 p-5 rounded-3">
              <div className="mb-3">
                <div
                  className="d-inline-block p-3 rounded-circle shadow-lg bg-primary bg-gradient"
                  style={{ width: 80, height: 80, lineHeight: "70px" }}
                >
                  <i className="fa fa-chalkboard fs-1" aria-hidden="true"></i>
                </div>
              </div>
              <h1 className="counting">{fNumber(20)}</h1>
              <h5>{t("Courses")}</h5>
            </div>
          </Col>
          <Col md="4">
            <div className="bg-secondary bg-gradient m-3 p-5 rounded-3">
              <div className="mb-3">
                <div
                  className="d-inline-block p-3 rounded-circle shadow-lg bg-secondary bg-gradient"
                  style={{ width: 80, height: 80, lineHeight: "70px" }}
                >
                  <i className="fa fa-users fs-1" aria-hidden="true"></i>
                </div>
              </div>
              <h1 className="counting">{fNumber(11142 + visitors)}</h1>
              <h5>{t("Visitors")}</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StatsSection;
