import React from "react";
import { Form } from "react-bootstrap";

const FormCheck = (props) => {
  const { onChange, errorMessage, labelClassName, isInvalid } = props;
  return (
    <Form.Group className="mb-3">
      <Form.Check
        {...props}
        type="checkbox"
        onChange={onChange}
        isInvalid={isInvalid}
        feedback={errorMessage}
        className={labelClassName}
      />
    </Form.Group>
  );
};

export default FormCheck;
