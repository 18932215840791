import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { roundCurrency } from "../helpers";

const CourseCard = (props) => {
  const { title, /*description, price,*/ url, image } = props;
  const { t } = useTranslation();
  return (
    <Card
      style={{
        boxShadow:
          "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
        border: "none",
      }}
    >
      <Card.Img
        variant="top"
        src={image ?? "/back-small.jpg"}
        style={{
          objectFit: "cover",
          width: "100%",
          height: 250,
          borderRadius: "20px 20px 0 0",
        }}
      />
      <Card.Body>
        <Card.Title
          style={{
            overflow: "hidden",
            WebkitLineClamp: 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            minHeight: 50,
          }}
        >
          {title}
        </Card.Title>
        {/* <p className="text-muted">
          {price ? `${roundCurrency(price)}$` : t("Free")}
        </p> */}
        {/* <Card.Text>{description}</Card.Text> */}
        <div className="d-grid gap-2">
          <Link to={url} className="btn btn-outline-primary btn-block">
            {t("Find out more")}
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CourseCard;
