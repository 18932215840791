import React, { useEffect, useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CourseCard from "../../components/courses/CourseCard";
import api from "../../components/helpers/api";
import PageHeader from "../../components/layouts/PageHeader";
import apiRoutes from "../../routes/apiRoutes";
import { Player, Video, DefaultUi, IconLibrary } from "@vime/react";
import { getLang } from "../../lang";
import clientRoutes from "../../routes/clientRoutes";

const Category = (props) => {
  const categoryId = props.match?.params?.id;
  const categorySlug = props.match?.params?.slug;
  const history = useHistory();
  const { t } = useTranslation();

  const [category, setCategory] = useState({});

  useEffect(() => {
    api
      .get(`${apiRoutes.categories.show}/${categoryId}/${categorySlug}`)
      .then((response) => {
        const { data } = response.data;
        document.title = data.title;
        setCategory(data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          history.push("/404");
        }
      });
  }, [categoryId, categorySlug, history]);

  return (
    <div>
      <PageHeader
        title={category.title}
        subtitle={category.description}
        cover={category.image?.image_url}
      />
      <Container className="mt-3">
        {category.promo_video && (
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Player icons="my-library">
                <Video>
                  <source
                    data-src={
                      `${process.env.REACT_APP_API_URL}/api/${getLang()}/` +
                      apiRoutes.categories.getVideo +
                      "/" +
                      category.id
                    }
                    type="video/mp4"
                  />
                </Video>

                <DefaultUi>
                  <IconLibrary
                    name="my-library"
                    resolver={(iconName) => {
                      return `/static/icons/vm-${iconName}.svg`;
                    }}
                  />
                </DefaultUi>
              </Player>
            </Col>
          </Row>
        )}

        <Card className="mt-3">
          <Card.Header className="bg-transparent">
            <h3 className="m-0">{t("Contents")}</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              {category.courses?.map((course, index) => (
                <Col md={3} className="mt-3" key={index}>
                  <CourseCard
                    title={course.title}
                    description={course.description}
                    price={course.price}
                    image={course.image?.card_url}
                    url={`${clientRoutes.courses.show}/${course.id}/${course.slug}`}
                  />
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Category;
