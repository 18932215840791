import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../components/helpers/api";
import PageHeader from "../../components/layouts/PageHeader";
import apiRoutes from "../../routes/apiRoutes";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const [policy, setPolicy] = useState({});

  document.title = t("Terms and conditions");

  useEffect(() => {
    api
      .get(apiRoutes.sitePolicies.termsAndConditions)
      .then((response) => setPolicy(response.data))
      .catch(() => {});
  }, []);

  return (
    <div>
      <PageHeader title={t("Terms and conditions")} />
      <Container className="mt-3">
        <div dangerouslySetInnerHTML={{ __html: policy.value }} />
      </Container>
    </div>
  );
};

export default TermsAndConditions;
