import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import FormInput from "../../components/inputs/FormInput";
import LoadingButton from "../../components/LoadingButton";
import apiRoutes from "../../routes/apiRoutes";
import { withSnackbar } from "notistack";
import moment from "moment";
import api from "../../components/helpers/api";
import { withTranslation } from "react-i18next";
import FormCard from "../../components/inputs/FormCard";
import clientRoutes from "../../routes/clientRoutes";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      redirect: new URLSearchParams(this.props.location.search).get("redirect"),
      errors: {},
    };
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("Verify Your Email");
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      code: this.state.code,
    };
    return api
      .post(`${apiRoutes.auth.verify}`, data)
      .then((res) => {
        this.props.setLogin({
          ...this.props.user,
          email_verified_at: moment(),
        });
        this.props.enqueueSnackbar(res.data.message, {
          variant: "success",
        });
        this.props.history.push(this.state.redirect ?? clientRoutes.home.index);
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { code: [t("Something went wrong")] },
          });
        }
      });
  };

  handleResend = async () => {
    const { t } = this.props;
    return api
      .post(`${apiRoutes.auth.resend}`, {})
      .then((res) => {
        this.props.enqueueSnackbar(res.data.message, {
          variant: "success",
        });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { code: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row>
          <Col sm={4} className="mx-auto mt-5 mb-4">
            <FormCard>
              <h3>{t("Verify Your Email")}</h3>
              <p className="text-muted">
                {t("We have sent you an email check your inbox")}
              </p>
              <hr />
              <Form>
                <FormInput
                  label={t("Code")}
                  placeholder={t("Code")}
                  type="text"
                  labelClassName="required"
                  name="code"
                  onChange={this.handleInput}
                  isInvalid={this.state.errors.code}
                  errorMessage={
                    this.state.errors.code && this.state.errors.code[0]
                  }
                />

                <LoadingButton
                  variant="primary"
                  type="submit"
                  className="d-block w-100"
                  onClick={this.handleForm}
                >
                  {t("Verify")}
                </LoadingButton>

                <LoadingButton
                  variant="outline-primary"
                  type="submit"
                  className="d-block w-100 mt-3"
                  onClick={this.handleResend}
                >
                  {t("Didn't receive the code?")}
                </LoadingButton>
              </Form>
            </FormCard>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
      }),
  };
};
export default withTranslation()(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail))
);
