import React, { Component } from "react";
import { Col, Form, FormSelect, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import countries from "../../config/countries";
import FormInput from "../inputs/FormInput";
import LoadingButton from "../LoadingButton";
import moment from "moment";
import api from "../helpers/api";
import apiRoutes from "../../routes/apiRoutes";
import { withSnackbar } from "notistack";

class UserSettings extends Component {
  constructor(props) {
    super(props);
    moment.locale("en");
    this.state = {
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      email: props.user.email,
      birth_date: moment(props.user.birth_date).format("YYYY-MM-DD"),
      country: props.user.country,
      mobile_number: props.user.mobile_number,
      gender: props.user.gender,
      lang: props.user.lang,
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({
      birth_date: moment(this.props.user.birth_date).format("YYYY-MM-DD"),
    });
  }

  handleForm = async () => {
    const { t } = this.props;
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      birth_date: this.state.birth_date,
      lang: this.state.lang,
      country: this.state.country,
      gender: this.state.gender,
      mobile_number: this.state.mobile_number,
    };
    this.setState({
      errors: {},
    });
    return api
      .patch(`${apiRoutes.profile.updateInfo}`, data)
      .then((res) => {
        // dispatch action
        this.props.setLogin(res.data.data);
        this.props.enqueueSnackbar(t('Updated successfully'), {
            variant: "success",
          });
      })
      .catch(({ response }) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            errors: response.data.errors,
          });
        } else {
          this.setState({
            errors: { email: [t("Something went wrong")] },
          });
        }
      });
  };

  handleInput = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Form>
        <Row>
          <Col>
            <FormInput
              label={t("First Name")}
              placeholder={t("First Name")}
              type="text"
              name="first_name"
              autoFocus
              labelClassName="required"
              value={this.state.first_name}
              onChange={this.handleInput}
              isInvalid={this.state.errors.first_name}
              errorMessage={
                this.state.errors.first_name && this.state.errors.first_name[0]
              }
            />
          </Col>
          <Col>
            <FormInput
              label={t("Last Name")}
              placeholder={t("Last Name")}
              type="text"
              labelClassName="required"
              name="last_name"
              value={this.state.last_name}
              onChange={this.handleInput}
              isInvalid={this.state.errors.last_name}
              errorMessage={
                this.state.errors.last_name && this.state.errors.last_name[0]
              }
            />
          </Col>
        </Row>

        <FormInput
          label={t("Email address")}
          placeholder={t("Email address")}
          type="email"
          name="email"
          labelClassName="required"
          value={this.state.email}
          onChange={this.handleInput}
          isInvalid={this.state.errors.email}
          errorMessage={this.state.errors.email && this.state.errors.email[0]}
        />

        <FormSelect
          label={t("Country")}
          name="country"
          labelClassName="required"
          value={this.state.country}
          onChange={this.handleInput}
          isInvalid={this.state.errors.country}
          errorMessage={
            this.state.errors.country && this.state.errors.country[0]
          }
        >
          {countries.map((country, idx) => (
            <option value={country.label} key={idx}>
              {country.label}
            </option>
          ))}
        </FormSelect>

        <FormInput
          label={t("Language")}
          placeholder={t("Language")}
          type="text"
          name="lang"
          labelClassName="required"
          value={this.state.lang}
          onChange={this.handleInput}
          isInvalid={this.state.errors.lang}
          errorMessage={this.state.errors.lang && this.state.errors.lang[0]}
        />

        <FormSelect
          label={t("Gender")}
          name="gender"
          labelClassName="required"
          value={this.state.gender}
          onChange={this.handleInput}
          isInvalid={this.state.errors.gender}
          errorMessage={this.state.errors.gender && this.state.errors.gender[0]}
        >
          <option value="male">{t("Male")}</option>
          <option value="female">{t("Female")}</option>
        </FormSelect>

        <FormInput
          label={t("Birth date")}
          placeholder={t("Birth date")}
          type="date"
          name="birth_date"
          labelClassName="required"
          value={this.state.birth_date}
          onChange={this.handleInput}
          isInvalid={this.state.errors.birth_date}
          errorMessage={
            this.state.errors.birth_date && this.state.errors.birth_date[0]
          }
        />

        <FormInput
          label={t("Mobile number")}
          placeholder={t("Mobile number")}
          type="text"
          name="mobile_number"
          value={this.state.mobile_number}
          onChange={this.handleInput}
          isInvalid={this.state.errors.mobile_number}
          errorMessage={
            this.state.errors.mobile_number &&
            this.state.errors.mobile_number[0]
          }
        />

        <LoadingButton
          variant="primary"
          type="submit"
          className="d-block w-100"
          onClick={this.handleForm}
        >
          {t("Update Data")}
        </LoadingButton>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogin: (user) =>
      dispatch({
        type: "SET_LOGIN",
        payload: user,
      }),
  };
};

export default withTranslation()(
  withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UserSettings))
);
