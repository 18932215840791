const testimonials = [
  {
    name: "Om Aminah",
    job: "Housewife",
    country: "Uzbekistan",
    comment: "Om Aminah comment",
    image: "/static/avatars/5.png",
  },
  {
    name: "Asheq Waziri",
    job: "Business owner",
    country: "Netherlands",
    comment: "Asheq Waziri comment",
    image: "/static/avatars/1.jpeg",
  },
  {
    name: "Fardin Shah",
    job: "Driver",
    country: "Italy",
    comment: "Fardin Shah comment",
    image: "/static/avatars/8.jpeg",
  },
  {
    name: "Tahmid",
    job: "Student",
    country: "UK",
    comment: "Tahmid comment",
    image: "/static/avatars/8.jpeg",
  },
  {
    name: "Behnoush Haghighian",
    job: "Engineer",
    country: "USA",
    comment: "Behnoush Haghighian comment",
    image: "/static/avatars/8.jpeg",
  },
  {
    name: "Ermin M",
    job: "Student",
    country: "Germany",
    comment: "Ermin M comment",
    image: "/static/avatars/8.jpeg",
  },
  {
    name: "Mirsab Zar",
    job: "Student",
    country: "USA",
    comment: "Mirsab Zar comment",
    image: "/static/avatars/7.jpeg",
  },
  {
    name: "Waliyullah",
    job: "Student",
    country: "Kyrgyzstan",
    comment: "Waliyullah comment",
    image: "/static/avatars/8.jpeg",
  },
];

export default testimonials;
