import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import clientRoutes from "../../routes/clientRoutes";

export const FileTypeTitle = (props) => {
  const { type } = props;

  if (type === "file") {
    return (
      <>
        <i className="fas fa-file-pdf fa-lg"></i>
      </>
    );
  }
  if (type === "video") {
    return (
      <>
        <i className="fas fa-video fa-lg"></i>
      </>
    );
  }
  if (type === "audio") {
    return (
      <>
        <i className="fas fa-headphones fa-lg"></i>
      </>
    );
  }
  return null;
};

const LessonFileCollapse = (props) => {
  const { file, type, fileId, fileType, url, closeSideBar } = props;
  return (
    <div>
      <ul className="list-group ps-3" style={{ listStyle: "none" }}>
        <Link
          to={url}
          className={` list-group-item border-0 fs-6 list-group-item-action ${
            fileId === file.id?.toString() && fileType === type ? "active" : ""
          }`}
          onClick={closeSideBar}
        >
          <FileTypeTitle type={type} /> {file.title}
        </Link>
      </ul>
    </div>
  );
};

const LessonFileTypeCollapse = (props) => {
  const { type, lesson, fileId, fileType, closeSideBar } = props;
  return (
    <>
      {type === "file" && (
        <ul className="list-group ps-3" style={{ listStyle: "none" }}>
          {lesson?.files?.map((file, index) => (
            <LessonFileCollapse
              file={file}
              fileId={fileId}
              fileType={fileType}
              type="file"
              key={index}
              closeSideBar={closeSideBar}
              url={`${clientRoutes.lessons.files}/${lesson.id}/${lesson.slug}/files/${file.id}`}
            />
          ))}
        </ul>
      )}
      {type === "video" && (
        <ul className="list-group ps-3" style={{ listStyle: "none" }}>
          {lesson?.videos?.map((video, index) => (
            <LessonFileCollapse
              file={video}
              fileId={fileId}
              fileType={fileType}
              type="video"
              key={index}
              closeSideBar={closeSideBar}
              url={`${clientRoutes.lessons.videos}/${lesson.id}/${lesson.slug}/videos/${video.id}`}
            />
          ))}
        </ul>
      )}
      {type === "audio" && (
        <ul className="list-group ps-3" style={{ listStyle: "none" }}>
          {lesson?.audios?.map((audio, index) => (
            <LessonFileCollapse
              file={audio}
              fileId={fileId}
              fileType={fileType}
              type="audio"
              key={index}
              closeSideBar={closeSideBar}
              url={`${clientRoutes.lessons.audios}/${lesson.id}/${lesson.slug}/audios/${audio.id}`}
            />
          ))}
        </ul>
      )}
    </>
  );
};

const LessonCollapse = (props) => {
  const { lesson, lessonId, fileId, fileType, closeSideBar } = props;
  const [open, setOpen] = useState(lesson.id?.toString() === lessonId);
  const fileTypes = ["file", "video", "audio"];

  useEffect(() => {
    setOpen(lesson.id?.toString() === lessonId);
  }, [lessonId, lesson]);

  return (
    <>
      <Link
        to={"#"}
        onClick={() => {
          setOpen(!open);
        }}
        className={`list-group-item fs-5 mt-1 list-group-item-action ${
          lesson?.paid && lesson?.course?.should_pay ? "disabled" : ""
        } ${
          lesson.id?.toString() === lessonId
            ? "border border-primary border-end-0 border-top-0 border-bottom-0 border-5 bg-light text-primary"
            : "border-0"
        }`}
      >
        {lesson?.paid && lesson?.course?.should_pay ? (
          <i className="fa fa-lock"></i>
        ) : (
          <i className="fas fa-chevron-down"></i>
        )}{" "}
        {lesson.title}
      </Link>
      {!(lesson?.paid && lesson?.course?.should_pay) && (
        <Collapse in={open}>
          <div>
            {fileTypes.map((type, index) => (
              <LessonFileTypeCollapse
                key={index}
                lesson={lesson}
                fileId={fileId}
                fileType={fileType}
                closeSideBar={closeSideBar}
                type={type}
              />
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
};

export default LessonCollapse;
