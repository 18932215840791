import { Avatar } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ArticleCard = (props) => {
  const {
    title,
    url,
    image,
    headline,
    comments,
    createdAt,
    authorImage,
    authorName,
  } = props;
  const { t } = useTranslation();
  return (
    <Card
      style={{
        boxShadow:
          "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
        border: "none",
      }}
    >
      <div style={{ position: "relative" }}>
        <Card.Img
          variant="top"
          src={image ?? "/back-small.jpg"}
          style={{
            objectFit: "cover",
            width: "100%",
            height: 250,
          }}
        />
        <h5 style={{ position: "absolute", top: 10, right: 10 }}>
          <Badge pill bg="secondary">
            {headline}
          </Badge>
        </h5>

        <Avatar
          style={{
            position: "absolute",
            bottom: "-20px",
            left: "20px",
            border: "4px solid white",
            borderRadius: "100%",
            boxSizing: "content-box",
          }}
          src={authorImage}
        >
          {authorName.charAt(0).toUpperCase()}
        </Avatar>
      </div>
      <Card.Body>
        <Card.Title
          style={{
            overflow: "hidden",
            WebkitLineClamp: 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            minHeight: 50,
          }}
          className="mt-3 fw-bold text-muted"
        >
          {title}
        </Card.Title>
        <div className="d-grid gap-2">
          <Link to={url} className="btn btn-outline-primary btn-block">
            {t("Find out more")}
          </Link>
        </div>
      </Card.Body>
      <Card.Footer className="text-muted">
        <Row className="justify-content-between">
          <Col>{moment(createdAt).format("MMMM DD, YYYY")}</Col>
          <Col className="text-end">
            {comments} {t("Comments")}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default ArticleCard;
