import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import apiRoutes from "../../routes/apiRoutes";
import clientRoutes from "../../routes/clientRoutes";
import api from "../helpers/api";

const CoursesSection = (props) => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    api
      .get(apiRoutes.courses.latest)
      .then((response) => setCourses(response.data.data))
      .catch(() => {});
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Container className="mt-5">
      <h1>{t("Explore New Courses")}</h1>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        containerClass="direction-ltr"
        transitionDuration={500}
        removeArrowOnDeviceType={["tablet", "mobile"]}
      >
        {courses.map((course, idx) => (
          <Card
            style={{
              width: "18rem",
              height: "100%",
              boxShadow:
                "0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)",
              border: "none",
            }}
            key={idx}
          >
            <Card.Img
              variant="top"
              style={{
                objectFit: "cover",
                width: "100%",
                height: 250,
              }}
              src={course.image?.card_url ?? "/back-small.jpg"}
            />
            <Card.Body>
              <Card.Title
                style={{
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  minHeight: 50,
                }}
              >
                {course.title}
              </Card.Title>
              <Card.Text
                style={{
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  minHeight: 50,
                }}
              >
                {course.description}
              </Card.Text>
              <div className="d-grid gap-2">
                <Link
                  to={`${clientRoutes.courses.show}/${course.id}/${course.slug}`}
                  className="btn btn-outline-primary btn-block"
                >
                  {t("Find out more")}
                </Link>
              </div>
            </Card.Body>
          </Card>
        ))}
      </Carousel>
    </Container>
  );
};

export default CoursesSection;
