import React, { Component } from "react";
import { Spinner, Button } from "react-bootstrap";

export default class LoadingButton extends Component {
  _isMounted = false;
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleClick = () => {
    this.setState({ isLoading: true });
    this.props.onClick().then(() => {
      if (this._isMounted) {
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <Button {...this.props} disabled={isLoading} onClick={this.handleClick}>
        {isLoading ? (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">Loading...</span>
          </>
        ) : (
          ""
        )}
        {this.props.children}
      </Button>
    );
  }
}
