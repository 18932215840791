import React, { Component, Fragment } from "react";
import IntroSection from "../components/Home/IntroSection";
// import JoinUsSection from "../components/Home/JoinUsSection";
import TopCoursesSection from "../components/Home/TopCoursesSection";
import TestimonialsSection from "../components/Home/TestimonialsSection";
import CoursesSection from "../components/Home/CoursesSection";
import StatsSection from "../components/Home/StatsSection";

export default class Home extends Component {
  render() {
    return (
      <Fragment>
        <IntroSection />
        <TopCoursesSection />
        {/* <JoinUsSection /> */}
        <StatsSection />
        <CoursesSection />
        <TestimonialsSection />
      </Fragment>
    );
  }
}
