import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Action, Fab } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { getSocialUrl } from "../components/helpers";
import ContactForm from "./ContactForm";

const ContactFab = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation();
  const { socialMedia } = props;

  const getWhatsAppAction = () => {
    const social = socialMedia.find(
      (thisSocial) => thisSocial.type === "whatsapp"
    );
    if (social) {
      return (
        <Action
          text={t("Whatsapp")}
          style={{ backgroundColor: "#25d366" }}
          onClick={(e) => {
            e.preventDefault();
            window.open(getSocialUrl(social));
          }}
        >
          <i className="fab fa-whatsapp" />
        </Action>
      );
    }
    return null;
  };

  const mainButtonStyles = {
    backgroundColor: "#242B5C",
  };
  return (
    <Fragment>
      <Fab
        mainButtonStyles={mainButtonStyles}
        icon={<i className="fa fa-phone"></i>}
        alwaysShowTitle={true}
      >
        <Action
          text={t("Message")}
          style={{ backgroundColor: "#ffc107" }}
          onClick={() => setModalShow(true)}
        >
          <i className="fa fa-comment-dots"></i>
        </Action>
        {getWhatsAppAction()}
      </Fab>

      <ContactForm show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    socialMedia: state.socialMedia.data,
  };
};

export default connect(mapStateToProps)(ContactFab);
