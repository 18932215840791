import React from "react";
import ReactDOM from "react-dom";
// import axios from "axios";
import cookie from "js-cookie";
import { Provider as StoreProvider } from "react-redux";
import { SnackbarProvider } from "notistack";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import apiRoutes from "./routes/apiRoutes";
import store from "./store";
import api from "./components/helpers/api";
import { getLang, storeLang } from "./lang";
import { ThemeProvider } from "react-bootstrap";
import axios from "axios";

let token = cookie.get("token");
const lang = getLang();

if (!lang) {
  storeLang(process.env.REACT_APP_FALLBACK_LANG);
} else if (lang === "ar") {
  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  document.getElementsByTagName("html")[0].setAttribute("lang", lang);
} else {
  document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  document.getElementsByTagName("html")[0].setAttribute("lang", lang);
}

const logout = () => {
  cookie.remove("token");
  token = null;
};

const render = () => {
  ReactDOM.render(
    // <React.StrictMode>
    <ThemeProvider dir="rtl">
      <StoreProvider store={store}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </StoreProvider>
    </ThemeProvider>,
    // </React.StrictMode>
    document.getElementById("root")
  );
};

if (token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/${getLang()}/`;
  axios
    .get(`${apiRoutes.auth.me}`)
    .then(async (res) => {
      store.dispatch({ type: "SET_LOGIN", payload: res.data });
      render();
    })
    .catch((err) => {
      const { response } = err;
      if (
        response &&
        response.data.message &&
        response.data.message === "Unauthenticated."
      )
        logout();
      render();
    });
} else {
  render();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
